class Vector2 {
    constructor(x, y, x2, y2) {
        this.x = x
        this.y = y;
        if (x2 && y2) {
            this.x = x - x2;
            this.y = y - y2;
        }
        this.magnitude = this.computeMagnitude();
    }
    computeMagnitude = () => {
        if (this.x === 0 && this.y === 0) {
            return 0;
        }

        if (this.x === 0) {
            return this.y
        }

        if (this.y === 0) {
            return this.x
        }

        return Math.max(Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2)));
    };
}

export default Vector2
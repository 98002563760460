import React, { useRef, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components';
import { Bubble } from './Bubble';
import { useGameLoop } from '#hooks';
import { World } from '../../../shared/physics';
import { animated } from 'react-spring';

const BubbleContainerWrapper = styled(animated.div)`
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right:0;
    top:0;
    bottom: 0;
`


const BubbleContainer = ({ props }) => {
    const boundaries = useRef(null);
    let env = new World(25);
    const envRef = useRef(env);

    const updateState = (elapsed) => {
        if (envRef.current) {
            envRef.current.updateParticlePositions(elapsed);
        }
    }
    useGameLoop(updateState);

    useLayoutEffect(() => {
        const rect = boundaries.current.getBoundingClientRect();
        envRef.current.setBoundaries(rect.width, rect.height);
    })


    return (
        <BubbleContainerWrapper ref={boundaries} style={props}>
            {envRef.current.getParticles().map((particle, index) => {
                return <Bubble particle={particle} key={index} />
            })}
        </BubbleContainerWrapper>
    );
}

export default BubbleContainer;

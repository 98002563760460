import { findMoves, checkWinner } from '..';

export const getImmediateWinningMoves = (game, player) => {
    const legalMoves = findMoves(game).filter(move => move !== null);
    player = player || game.filter(p => p !== null).length % 2 + 1;
    return legalMoves.filter(move => isWinning(game, move, player) !== false);
}

const isWinning = (game, move, player) => {
    const newGame = [...game];
    newGame[move] = player;
    return checkWinner(newGame);
}
import React, { useLayoutEffect, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { colors } from '#themes';

const CellWrapper = styled.div`
    width: ${props => props.cellsize}rem;
    height: ${props => props.cellsize}rem;
    border-left: calc(0.1 * ${props => props.cellsize}rem) solid black;
    border-top: calc(0.1 * ${props => props.cellsize}rem) solid black;
    box-sizing: border-box;
    transform-style: preserve-3d;
`

const CellChip = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${props => props.value === 1 ? colors.eyelashViper : colors.white};
    transform: scale(1.1) translateZ(-1px);
`

const BoardCell = ({ value, cellsize, boardRef }) => {
    const initialValue = useRef(value);
    const cellRef = useRef();
    let animate = false;
    if (initialValue.current === null && value !== null) {
        animate = true;
    }

    return (
        <CellWrapper cellsize={cellsize}>
            <animated.div ref={cellRef}
                style={{ width: '100%', height: '100%' }}>
                {value && <CellChip value={value} />}
            </animated.div>
        </CellWrapper>
    );
}

export default BoardCell;

import { Particle } from '.';
import { detectCollision, getParticleCollisionResolution, getUserParticleCollisionResolution } from './detectionAndResolution'
import { Vector, SortedSet } from './helper';

class World {
    constructor(amountOfParticles = 1, gravity = 0, friction = 1) {
        this.particles = new SortedSet();
        this.rightBoundary = 1000;
        this.bottomBoundary = 1000;
        this.userParticle = null;
        this.initParticles(amountOfParticles);
        this.gravity = gravity;
        this.friction = friction;
    }

    initParticles = (amountOfParticles) => {
        for (let i = 0; i < amountOfParticles; i++) {
            let size = Math.random() * 70 + 10;
            let x = Math.floor(Math.random() * (this.rightBoundary - size));
            let y = Math.floor(Math.random() * (this.bottomBoundary - size));
            this.particles.add(new Particle([x, y], size));
        }
    }

    setBoundaries = (rightBoundary, bottomBoundary) => {
        this.rightBoundary = rightBoundary;
        this.bottomBoundary = bottomBoundary;
        if (this.rightBoundary < 700) {
            this.particles.resize(12);
        }
    }

    checkBoundaryCollision = () => {
        this.particles.forEach(p => {
            // update x vector
            if (p.x < 0) {
                p.vector = Vector.positiveX(p.vector)
                p.setLastCollision(null);
            };
            if ((p.x + p.radius) > this.rightBoundary) {
                p.vector = Vector.invertX(Vector.positiveX(p.vector))
                p.setLastCollision(null);
            };
            // update y vectorV
            if (p.y < 0) {
                p.vector = Vector.positiveY(p.vector)
                p.setLastCollision(null);
            };
            if ((p.y + p.radius + 2) > this.bottomBoundary) {
                p.vector = Vector.invertY(Vector.positiveY(p.vector))
                p.setLastCollision(null);
            };
        })
    }

    addParticle = (particle) => {
        this.particles.add(particle);
    }

    addUserParticle = (particle) => {
        this.userParticle = particle;
    }

    updateUserParticle = (vector, position) => {
        this.userParticle.vector = vector;
        this.userParticle.x = position.x;
        this.userParticle.y = position.y;
    }

    checkUserCollision = () => {
        if (this.userParticle === null || this.userParticle === undefined) {
            return;
        }


        this.particles.forEach(p => {
            if (detectCollision(p, this.userParticle)) {


                const vector = getUserParticleCollisionResolution(p, this.userParticle);


                p.vector = vector
            }
        })
    }


    checkParticleCollisions = () => {
        let collisionToCheck = new Set();

        // Strategy 1
        let previous1 = undefined;
        let previous2 = undefined;
        let previous3 = undefined;
        let previous4 = undefined;

        this.particles.sort(p => p.x);

        const possibleCollision = (p1, p2) => {
            const minimumDistance = (p1.radius + p2.radius) * 1.5;

            if (Math.abs(p1.x - p2.x) < minimumDistance) {
                collisionToCheck.add([p1, p2]);
            }
        }

        this.particles.forEach((p, index) => {
            let max = Math.max(0, index - 4)

            // for (let i = index - 1; i > max; i--) {
            //     if (i < 1) return;
            //     let previousP = this.particles[i];
            //     possibleCollision(p, previousP);
            // }


            if (!previous1) {
                previous1 = p;
                return;
            }
            if (!previous2) {
                possibleCollision(p, previous1);
                previous2 = previous1;
                previous1 = p;
                return;
            }
            if (!previous3) {
                possibleCollision(p, previous1);
                possibleCollision(p, previous2);
                previous3 = previous2;
                previous2 = previous1;
                previous1 = p;
                return;
            }
            if (!previous4) {
                possibleCollision(p, previous1);
                possibleCollision(p, previous2);
                possibleCollision(p, previous3);
                previous4 = previous3;
                previous3 = previous2;
                previous2 = previous1;
                previous1 = p;
                return;
            }
            possibleCollision(p, previous1);
            possibleCollision(p, previous2);
            possibleCollision(p, previous3);
            possibleCollision(p, previous4);
            previous4 = previous3;
            previous3 = previous2;
            previous2 = previous1;
            previous1 = p;
        })

        collisionToCheck = Array.from(collisionToCheck).filter((pair) => { return detectCollision(pair[0], pair[1]) })

        // Strategy 2
        // this.particles.forEach(p1 => {
        //     this.particles.forEach(p2 => {
        //         if (p1 === p2) {
        //             return;
        //         }
        //         collisionToCheck.push([p1, p2]);
        //     })
        // })

        // collisionToCheck = collisionToCheck.filter((pair) => { return detectCollision(pair[0], pair[1]) })


        collisionToCheck.forEach(pair => {
            let p1 = pair[0];
            let p2 = pair[1];
            const { vector1, vector2 } = getParticleCollisionResolution(p1, p2);
            p1.vector = Vector.scalarProduct(vector1, 1);
            p2.vector = Vector.scalarProduct(vector2, 1);
        })
    }

    updateParticlePositions = (elapsedTime) => {
        elapsedTime = elapsedTime / 20;
        if (elapsedTime > 100) {
            elapsedTime = 1;
        }

        this.checkBoundaryCollision();
        this.checkParticleCollisions();
        this.checkUserCollision();


        this.particles.forEach(p => {
            // friction
            p.vector.x = p.vector.x * this.friction;
            p.vector.y = p.vector.y * this.friction;
            if (Math.abs(p.vector.x) < 0.05 && Math.abs(p.vector.y) < 0.05) {
                p.vector.x = 0;
                p.vector.y = 0;
            }

            p.vector.y = p.vector.y + this.gravity;
            p.x = p.x + p.vector.x * elapsedTime;
            // constraint to floor
            p.y = Math.min(p.y + p.vector.y * elapsedTime, this.bottomBoundary - p.radius - 1);
            // gravity
        })

    }

    getParticles = () => {
        return Array.from(this.particles.set);
    }
    // private
    collisionDetectionBetweenTwoParticles = (p1, p2) => {
        let c1 = [p1.x, p1.y, p1.radius];
        let c2 = [p2.x, p2.y, p2.radius];
        return detectCollision(c1, c2);
    }
}

export default World
import { useRef, useEffect } from 'react';

const useGameLoop = (updateFunction, interval) => {
    const refreshInterval = interval || 1;
    const intervalElapsedTime = useRef(0);
    const elapsedTime = useRef(0);
    const loopRef = useRef();

    const loop = (fn) => {
        const now = performance.now();
        if (intervalElapsedTime.current + refreshInterval < now) {
            const elapsed = now - intervalElapsedTime.current;

            if (updateFunction && updateFunction instanceof Function) {
                updateFunction(elapsed);
            }

            intervalElapsedTime.current = now;
        }
        window.requestAnimationFrame(loop);
    }

    const getElapsed = () => {
        if (!elapsedTime.current) {
            return undefined;
        }
        const now = performance.now();
        const elapsed = now - elapsedTime.current;
        elapsedTime.current = now;
        return elapsed;
    }

    // need to register a game loop;
    useEffect(() => {
        // init
        if (!loopRef.current) {
            loopRef.current = window.requestAnimationFrame(() => loop());
        }
        if (!elapsedTime.current) {
            elapsedTime.current = performance.now();
        }


        return () => {
            window.cancelAnimationFrame(loopRef.current);
        }
    }, [])
    return{
        getElapsed,
    }
}

export default useGameLoop;

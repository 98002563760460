import Vector2 from './Vector2';

class Vector {
    static instance;
    constructor() {
        if (Vector.instance) {
            return Vector.instance
        }
        Vector.instance = this;
    }
    dot = (v1, v2) => {
        return v1.x * v2.x + v1.y * v2.y;
    }
    normalizeV2 = (v) => {
        if (v.magnitude === 0) {
            return new Vector(0, 0);
        }
        return new Vector2(v.x / v.magnitude, v.y / v.magnitude);
    }
    add = (v1, v2) => {
        return new Vector2(v1.x + v2.x, v1.y + v2.y);
    }
    subtract = (v1, v2) => {
        return new Vector2(v1.x - v2.x, v1.y - v2.y);
    }
    scalarProduct = (v, s) => {
        return new Vector2(v.x * s, v.y * s);
    }
    invert = (v) => {
        return new Vector2(-v.x, -v.y)
    }
    create = (x1, y1, x2, y2) => {
        return new Vector2(x2 - x1, y2 - y1);
    }
    invertX = v => {
        return new Vector2(-v.x, v.y);
    }
    invertY = v => {
        return new Vector2(v.x, -v.y);
    }
    positiveX = v => {
        return new Vector2(Math.abs(v.x), v.y)
    }
    positiveY = v => {
        return new Vector2(v.x, Math.abs(v.y))
    }
}


export default new Vector();
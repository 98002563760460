import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { animated, useTransition, config } from 'react-spring';

const Container = styled.div`
    position: relative;
`

const WordWrapContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    display:flex;
    flex-wrap: wrap;
    font-size: ${props => props.fontSize}rem;
    max-width: ${props => props.width}ch;
`

const Scrambler = ({ sentence, width, fontSize}) => {
    const [words1, setWords1] = useState(sentence.split(' '));
    const [words2, setWords2] = useState([]);
    width = width || 24;

    useEffect(() => {
        if (words1.length === 0) {
            setWords1(sentence.split(' '));
            setWords2([]);
        }
        else {
            setWords1([]);
            setWords2(sentence.split(' '));
        }
    }, [sentence])

    return (
        <Container>
            <WordWrapContainer width={width} fontSize={fontSize}>
                {words1.map((w, index) => {
                    return <Words word={w} key={index} />
                })}
            </WordWrapContainer>
            <WordWrapContainer width={width} fontSize={fontSize}>
                {words2.map((w, index) => {
                    return <Words word={w} key={index} />
                })}
            </WordWrapContainer>
        </Container>

    );
}


const WordWrapper = styled.div`
    color: #fff;
    display: flex;
    flex-direction: row;
`
const Words = ({ word }) => {
    const generateRandomNumber = (range) => {
        let pos = Math.floor(Math.random() * 2) % 2 === 0 ? -1 : 1;
        return (Math.random() * 100 + 20) * pos;
    }

    const letters = [...word];
    const letterObjects = letters.map((l, index) => {
        return { letter: l, key: index, position: [generateRandomNumber(), generateRandomNumber()] }
    })

    const transitions = useTransition(letterObjects, item => item.key, {
        from: item => ({ opacity: 0, transform: `translate3D(${item.position[0]}px, ${item.position[1] * 2}px, 0` }),
        enter: { opacity: 1, transform: `translate3D(0px, 0px, 0)` },
        leave: item => ({ opacity: 0, transform: `translate3D(${item.position[0]}px, ${item.position[1]}px, 0` }),
        config: { duration: 800, tension: 280, friction: 80 }, trail: 80
    })

    return (
        <WordWrapper>
            {transitions.map(({ item, props, key }) => {
                return <animated.div key={key} style={props}>{item.letter}</animated.div>
            })}
            &nbsp;
        </WordWrapper>
    )
}

export default Scrambler;

import { useRef, useEffect } from 'react';


const useScrollTo = (scrollLocations, callback) => {
    const currentLocation = useRef();
    const previousScroll = useRef(0);
    const minimumElapsedTime = 100;

    const scrollDown = (e) => {
        window.scrollBy(0, 10);
    }

    const scrollUp = () => {
        window.scrollBy(0, -10);
    }

    useEffect(() => {
        // initialize the current location
        currentLocation.current = window.scrollY

        // Callback on scroll event
        const scrollTo = (e) => {
            const actualScrollLocation = window.scrollY;
            const direction = actualScrollLocation > currentLocation.current ? 1 : -1;
            const locations = scrollLocations.map(element => element.current.offsetTop).sort((a, b) => a - b);
            let targetScrollLocation = 0;
            let elementIndex = 0;

            // if down reverse array and set target scroll location to max
            if (direction === -1) {
                locations.reverse();
                targetScrollLocation = Number.MAX_SAFE_INTEGER;
            }

            // get the new scroll location depending up or down
            locations.forEach((loc, index) => {
                if (direction === 1 && targetScrollLocation > actualScrollLocation) {
                    return;
                }
                if (direction === -1 && targetScrollLocation < actualScrollLocation) {
                    return;
                }
                if (direction === 1 && loc > targetScrollLocation) {
                    elementIndex = index
                    targetScrollLocation = loc;
                }
                if (direction === -1 && loc < targetScrollLocation) {
                    elementIndex = index
                    targetScrollLocation = loc;
                }
            })

            // if @ a start position (place in the array) OR time between last scroll event as elapsed
            if (
                locations.indexOf(currentLocation.current) !== -1
                || performance.now() > previousScroll.current + minimumElapsedTime
            ) {
                // up or down
                if (direction === -1) {
                    elementIndex = (scrollLocations.length - 1) - elementIndex;
                }
                window.scrollTo({ behavior: 'smooth', top: targetScrollLocation })

                // invoke callback when started scrolling
                callback(elementIndex);
            }

            previousScroll.current = performance.now();
            currentLocation.current = actualScrollLocation;
        }
        window.addEventListener('scroll', scrollTo);

        return () => {
            window.removeEventListener('scroll', scrollTo);
        }
    }, [])

    return {
        scrollDown,
        scrollUp
    }
}

export default useScrollTo;
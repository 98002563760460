/**
 * State management wrapper
 */
import { useEffect, useState, useRef } from "react";


const useStore = (func, value) => {
    const store = new Store();

    // Type validation
    if (!func || typeof func !== 'function') {
        throw new Error('invalid parameter type, must be a function \nstate => state.fieldName');
    }

    // CODE starts here
    const key = func.toString().split('.')[1];  // Hack to get field past in function, used to set the value in the store
    const keyRef = useRef(key);                 // Remember the key

    // if value passed as argument, set the store state to the initial value
    if (value) {
        store.setInitialState(key, value);
    }

    const [state, setState] = useState(func(store.getState()));

    const updateValue = () => {
        setState(store.getState(keyRef.current));
    }

    const newValue = (value) => {
        store.setState(keyRef.current, value);
    }

    // We need to register for updates
    useEffect(() => {
        store.subscribe(updateValue, keyRef.current);
        return () => {
            // Cleanup
            store.unsubscribe(updateValue, keyRef.current);
        }
    }, [])

    return [
        state,
        newValue
    ]
}

export default useStore;

class Store {
    static instance;
    constructor() {
        if (Store.instance) {
            return Store.instance
        }
        this.store = {};
        this.subscriptions = {};
        this.subs = new Set();
        Store.instance = this;
    }
    setState = (key, value) => {
        this.store[key] = value;
        if (!this.subscriptions[key]) {
            this.subscriptions[key] = new Set();
        }
        this.broadcast(key);
    }
    setInitialState = (key, value) => {
        this.store[key] = value;
        if (!this.subscriptions[key]) {
            this.subscriptions[key] = new Set();
        }
    }
    getState = (key) => {
        if (!key) {
            return this.store;
        }
        return this.store[key];
    }
    subscribe = (fn, key = undefined) => {
        if (!key) {
            this.subs.add(fn);
        }

        if (!this.subscriptions[key]) {
            this.subscriptions[key] = new Set();
        }

        this.subscriptions[key].add(fn);
    }
    unsubscribe = (fn, key = undefined) => {
        if (!key) {
            this.subs.delete(fn);
            return;
        }
        this.subscriptions[key].delete(fn);
    }
    broadcast = (key = undefined) => {
        if (key) {
            this.subscriptions[key].forEach(fn => fn());
        }
    }
}
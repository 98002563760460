// GENERAL GAME PLAY
// check winner => return false or {winner: 1||2, type: 'r' || 'c' || '/' || '\', start: 35}
/**
 *   0  1  2  3  4  5  6
 *   7  8  9  10 11 12 13
 *   14 15 16 17 18 19 20
 *   21 22 23 24 25 26 27
 *   28 29 30 31 32 33 34
 *   35 36 37 38 39 40 41
 */
const checkWinner = (game, move) => {

    if (game.length !== 42) {
        throw new Error('invalid game size');
    }

    // if move < 7 return;
    if (move && move < 8) {
        return false;
    }

    // -- CHECK ROWS --
    let rowsToCheck = [];

    // 1. find rows to check
    for (let i = 38; i > 9; i -= 7) {
        if (game[i] === null) {
            break;
        }
        rowsToCheck.push(i - 3)
    }
    // 2. check individual row
    const checkRow = (start) => {
        let initialStart = start;
        let initialPlayer = game[start];
        let count = 0;
        for (let i = (start + 1); i < (start + 7); i++) {

            if (initialPlayer !== game[i] || initialPlayer === null) {
                count = 0;
                initialStart = i;
                initialPlayer = game[i];
                continue;
            }
            count++;
            if (count === 3) {
                return {
                    winner: initialPlayer,
                    start: initialStart,
                    type: 'r'
                };
            }
        }
        return false;
    }
    // 3. check every rows
    for (let i = 0; i < rowsToCheck.length; i++) {
        let win = checkRow(rowsToCheck[i])
        if (win) {
            return win;
        }
    }

    // -- CHECK COLUMNS --
    // 1. find columns to check
    let diagLeftToCheck = [];
    let diagRightToCheck = [];
    let columnsToCheck = [];
    for (let i = 14; i < 21; i++) {
        if (game[i] !== null) {
            columnsToCheck.push(i - 14);
            // left diag \
            if (i < 20) {
                let start = i - 16;
                // left diags starts => -2(7), -1(14), 0, 1, 2, 3
                start = start < 0 ? Math.abs(start * 7) : start;
                diagLeftToCheck.push(start)
            }
            // right diag /
            if (i > 14) {
                let start = i - 12;
                // right diags starts => 3, 4, 5, 6, 7(13), 8(20)
                start = start > 6 ? (start - 6) * 7 + 6 : start;
                diagRightToCheck.push(start);
            }
        }
    }
    // 2. check individual column function
    const checkColumn = (start) => {
        let initialStart = start;
        let initialPlayer = game[start];
        let count = 0;
        for (let i = start + 7; i < 42; i += 7) {
            if (game[i] !== initialPlayer || initialPlayer === null) {

                initialStart = i;
                initialPlayer = game[i];
                count = 0;
                continue;
            }
            
            count++;
            if (count === 3) {
                return {
                    winner: initialPlayer,
                    start: initialStart,
                    type: 'c'
                }
            }
        }
        return false;
    }
    // 3. check every column
    for (let i = 0; i < columnsToCheck.length; i++) {
        let win = checkColumn(columnsToCheck[i])
        if (win) {
            return win;
        }
    }

    // check => \ 

    // 1. check unique diagonal
    const checkDiagLeft = (start) => {
        let initialStart = start;
        let initialPlayer = game[start];
        let max = start === 3 ? 28 : 42;
        let count = 0;
        for (let i = start + 8; i < max; i += 8) {
            if (initialPlayer !== game[i] || initialPlayer === null) {
                count = 0;
                initialPlayer = game[i];
                initialStart = i;
                continue;
            }
            count++;
            if (count === 3) {
                return {
                    winner: initialPlayer,
                    start: initialStart,
                    type: 'dl'
                }
            }
        }
        return false;
    }
    // 2. check every possible diag left
    for (let i = 0; i < diagLeftToCheck.length; i++) {
        let win = checkDiagLeft(diagLeftToCheck[i]);
        if (win) {
            return win;
        }
    }

    // check => /
    // 1. check unique diagonal
    const checkDiagRight = (start) => {
        let initialStart = start;
        let initialPlayer = game[start];
        let max = Math.min(start * 7 + 1, 42 );
        let count = 0;
        for (let i = start + 6; i < max; i += 6) {
            if (initialPlayer !== game[i] || initialPlayer === null) {
                count = 0;
                initialPlayer = game[i];
                initialStart = i;
                continue;
            }
            count++;
            if (count === 3) {
                return {
                    winner: initialPlayer,
                    start: initialStart,
                    type: 'dr'
                }
            }
        }
    }
    // 2. check every possible diag right
    for (let i = 0; i < diagLeftToCheck.length; i++) {
        let win = checkDiagRight(diagRightToCheck[i]);
        if (win) {
            return win;
        }
    }
    return false;
}

export {
    checkWinner
}
import { getImmediateWinningMoves } from './getImmediateWinningMoves';
import { findMoves } from "../";

// its a move where result is that immediate winning move is 2
export const getFutureWinningMoves = (game, player) => {
    const legalMoves = findMoves(game);
    player = player || game.filter(player => player !== null).length % 2 + 1;
    return legalMoves.filter(move => getImmediateWinningMoves(generateNewGameState(game, move, player), player).length > 1);
}

const generateNewGameState = (game, move, player) => {
    const newGame = [...game];
    newGame[move] = player;
    return newGame;
}
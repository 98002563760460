const colors = {
    eyelashViper: '#F2C94C',
    carbon: '#333333',
    black: '#000000',
    white: '#FFFFFF',
    cynicalBlack: '#171717',
    chaosBlack: '#0f0f0f',
}

export {
    colors,
}
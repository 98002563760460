class SortedSet {
    constructor(sortedSet) {
        try {
            this.set = new Set();
            if (sortedSet) {
                this._init(sortedSet);
            }
        }
        catch (e) {
            throw new Error('invalid data type')
        }
        this.size = this.set.size;
    }
    /**
     * obj
     * v => v.[prop]
     * 
     * @param {*} fn 
     */
    sort = (fn) => {
        const sortedArray = Array.from(this.set);
        try {
            if (typeof fn !== 'function') throw new Error('invalid argument type, must be a function')
            const sorting = (a, b) => {
                let sortItemA = fn(a);
                let sortItemB = fn(b);
                return sortItemA - sortItemB
            }

            sortedArray.sort(sorting)
            this.set = new Set(sortedArray);
        }
        catch (e) {
            throw new Error(e);
        }
    }
    add = (entry) => {
        this.set.add(entry);
    }
    delete = (entry) => {
        this.set.delete(entry);
    }
    entries = () => {
        return this.set.entries();
    }
    forEach = (fn) => {
        this.set.forEach(fn);
    }
    map = (fn) => {
        const arr = Array.from(this.set);
        return arr.map(fn);
    }
    has = (value) => {

    }
    get = (index) => {

    }

    resize = (size) => {
        const newSet = new Set();
        let count = 0;
        this.set.forEach(v => {
            if (count < size) {
                newSet.add(v)
            }
            count++;
        })
        this.set = newSet;
    }

    _init = (sortedSet) => {
        if (sortedSet instanceof Object.getPrototypeOf(this)) {
            this.set = new Set(sortedSet.set);
        }
        if (sortedSet instanceof Array || sortedSet instanceof Set) {
            this.set = new Set(sortedSet)
        }
    }
}

export default SortedSet;
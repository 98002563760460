import React from 'react';
import styled from 'styled-components';


const AboutWrapper = styled.div`
    position: relative;
    width: min(20rem, 80vw);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem 4rem 1rem;
`

const ContactWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 1rem;
`

const LogoWrapper = styled.div`
    margin: 0 .3rem;
    cursor: pointer;
    transition: all .3s ease;
    filter: grayscale(1);
    :hover&{
        filter: grayscale(1) opacity(.6);
        transition: all .3s ease;
    }
`



const About = () => {
    const links = {
        mail: 'mail',
        gitlab: 'https://gitlab.com/2678130',
        facebook: 'https://www.facebook.com/begeron.thierry',
        linkedin: 'https://www.linkedin.com/in/thierry-bergeron-081175200/',
    }

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute('name');
        // mail
        if (name === links.mail) {
            // add email to clipboard
        }

        else {
            window.open(links[name]);
        }
    }
    <svg width="24" height="24" class="tanuki-logo" viewBox="0 0 36 36">
        <path class="tanuki-shape tanuki-left-ear" fill="#e24329" d="M2 14l9.38 9v-9l-4-12.28c-.205-.632-1.176-.632-1.38 0z"></path>
        <path class="tanuki-shape tanuki-right-ear" fill="#e24329" d="M34 14l-9.38 9v-9l4-12.28c.205-.632 1.176-.632 1.38 0z"></path>
        <path class="tanuki-shape tanuki-nose" fill="#e24329" d="M18,34.38 3,14 33,14 Z"></path>
        <path class="tanuki-shape tanuki-left-eye" fill="#fc6d26" d="M18,34.38 11.38,14 2,14 6,25Z"></path>
        <path class="tanuki-shape tanuki-right-eye" fill="#fc6d26" d="M18,34.38 24.62,14 34,14 30,25Z"></path>
        <path class="tanuki-shape tanuki-left-cheek" fill="#fca326" d="M2 14L.1 20.16c-.18.565 0 1.2.5 1.56l17.42 12.66z"></path>
        <path class="tanuki-shape tanuki-right-cheek" fill="#fca326" d="M34 14l1.9 6.16c.18.565 0 1.2-.5 1.56L18 34.38z"></path>
    </svg>

    return (
        <AboutWrapper>
            <h1>Thierry</h1>
            <p>I am a technology enthusiast interested in a wide variety of topics. From game development to industrial automation. I like to explore different software solutions to various problems. Always feel free to contact for various inquiry</p>
            <ContactWrapper>
                <LogoWrapper name={links.mail} onClick={handleClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 20H4C2.89543 20 2 19.1046 2 18V5.913C2.04661 4.84255 2.92853 3.99899 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20ZM4 7.868V18H20V7.868L12 13.2L4 7.868ZM4.8 6L12 10.8L19.2 6H4.8Z" fill="#2E3A59" />
                    </svg>
                </LogoWrapper>
                <LogoWrapper name="gitlab" onClick={handleClick}>
                        <svg width="24" height="24" class="tanuki-logo" viewBox="0 0 36 36">
                            <path class="tanuki-shape tanuki-left-ear" fill="#e24329" d="M2 14l9.38 9v-9l-4-12.28c-.205-.632-1.176-.632-1.38 0z"></path>
                            <path class="tanuki-shape tanuki-right-ear" fill="#e24329" d="M34 14l-9.38 9v-9l4-12.28c.205-.632 1.176-.632 1.38 0z"></path>
                            <path class="tanuki-shape tanuki-nose" fill="#e24329" d="M18,34.38 3,14 33,14 Z"></path>
                            <path class="tanuki-shape tanuki-left-eye" fill="#fc6d26" d="M18,34.38 11.38,14 2,14 6,25Z"></path>
                            <path class="tanuki-shape tanuki-right-eye" fill="#fc6d26" d="M18,34.38 24.62,14 34,14 30,25Z"></path>
                            <path class="tanuki-shape tanuki-left-cheek" fill="#fca326" d="M2 14L.1 20.16c-.18.565 0 1.2.5 1.56l17.42 12.66z"></path>
                            <path class="tanuki-shape tanuki-right-cheek" fill="#fca326" d="M34 14l1.9 6.16c.18.565 0 1.2-.5 1.56L18 34.38z"></path>
                        </svg>
                </LogoWrapper>
                <LogoWrapper name="facebook" onClick={handleClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.00195 12.002C2.00312 16.9214 5.58036 21.1101 10.439 21.881V14.892H7.90195V12.002H10.442V9.80204C10.3284 8.75958 10.6845 7.72064 11.4136 6.96698C12.1427 6.21332 13.1693 5.82306 14.215 5.90204C14.9655 5.91417 15.7141 5.98101 16.455 6.10205V8.56104H15.191C14.7558 8.50405 14.3183 8.64777 14.0017 8.95171C13.6851 9.25566 13.5237 9.68693 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8174 21.0506 22.502 16.2518 21.9475 10.9611C21.3929 5.67041 16.7932 1.73997 11.4808 2.01722C6.16831 2.29447 2.0028 6.68235 2.00195 12.002Z" fill="#2E3A59" />
                    </svg>
                </LogoWrapper>
                <LogoWrapper name="linkedin" onClick={handleClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 21H9V9H13V11C13.8526 9.91525 15.1456 9.26857 16.525 9.237C19.0056 9.25077 21.0072 11.2694 21 13.75V21H17V14.25C16.84 13.1326 15.8818 12.3036 14.753 12.306C14.2593 12.3216 13.7932 12.5378 13.4624 12.9046C13.1316 13.2715 12.9646 13.7573 13 14.25V21ZM7 21H3V9H7V21ZM5 7C3.89543 7 3 6.10457 3 5C3 3.89543 3.89543 3 5 3C6.10457 3 7 3.89543 7 5C7 5.53043 6.78929 6.03914 6.41421 6.41421C6.03914 6.78929 5.53043 7 5 7Z" fill="#2E3A59" />
                    </svg>
                </LogoWrapper>
            </ContactWrapper>
        </AboutWrapper>
    );
}

export default About;

import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '#themes';
import { useScrollTo, useStore } from '#hooks';
import Scrambler from '../components/scrambler/Scrambler';
import BubbleContainer from '../components/particles/BubbleContainer';
import { useSpring, animated } from 'react-spring';
import Connect4 from '../connect4/Connect4';
import { ArrowDown, ArrowUp } from '../../shared/ui';


const LandingWrapper = styled.main`
    touch-action: none;    
`

const ScrollLocation = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: ${props => props.backgroundColor};
`

const ScramblerWrapper = styled(animated.div)`
    position: fixed;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const ArrowDownWrapper = styled.div`
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, 0);
`

const ArrowUpWrapper = styled.div`
    position: fixed;
    top: 3rem;
    left: 3rem;
`

const Landing = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [hideHeader, setHideHeader] = useStore(state => state.hideHeader, false);
    const connect4Ref = useRef(null);
    const scrollRef1 = useRef(null);
    const scrollRef2 = useRef(null);
    const scrollRef3 = useRef(null);
    const timeout = useRef(undefined);
    const [text, setText] = useState('Repository of personal projects');
    const { scrollDown, scrollUp } = useScrollTo([scrollRef1, scrollRef2, scrollRef3], callback);

    const [scramblerProps, setScrambler] = useSpring(() => ({ opacity: 1, config: { duration: 1000 } }))
    const [connect4Props, setConnect4Props] = useSpring(() => ({ opacity: 0, config: { duration: 1000 } }))
    const [worldProps, setWorldProps] = useSpring(() => ({ opacity: 0, config: { duration: 1500 } }))

    function callback(position) {
        const removeConnectFour = () => {
            setConnect4Props({ opacity: 0, config: { duration: 200 } });
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        }
        const removeWorld = () => {
            setWorldProps({ opacity: 0, config: { duration: 200 } });
        }
        // Initial landing spot
        if (position === 0) {
            removeConnectFour();
            removeWorld();
            setScrollPosition(0);
            setHideHeader(false);
            setScrambler({ opacity: 1 });
            setText('Repository of personal projects');
        };

        // Connect 4
        if (position === 1) {
            removeWorld();
            setScrollPosition(1);
            setScrambler({ opacity: 1 });
            setText('Play connect four against a Monte-Carlo based AI');
            timeout.current = setTimeout(() => {
                setConnect4Props({ opacity: 1, config: { duration: 1000 } })
                setScrambler({ opacity: 0 });
                setHideHeader(true);
                timeout.current = undefined;
            }, 4500)
        }

        // 2D physics
        if (position === 2) {
            removeConnectFour();
            setScrollPosition(2);
            setScrambler({ opacity: 1 });
            setWorldProps({ opacity: 1, config: { duration: 1500 } });
            setText('A simple vector 2D physics engine written in Javascript');
        }

    }

    return (
        <LandingWrapper>
            <ScramblerWrapper style={scramblerProps}>
                <Scrambler sentence={text} fontSize={3} />
            </ScramblerWrapper>
            <ScrollLocation ref={scrollRef1} backgroundColor={colors.chaosBlack}>
            </ScrollLocation>
            <ScrollLocation ref={scrollRef2} backgroundColor={colors.cynicalBlack}>
                {
                    <Connect4 ref={connect4Ref} props={connect4Props} />
                }
            </ScrollLocation>
            <ScrollLocation ref={scrollRef3} backgroundColor={colors.cynicalBlack}>
                {
                    scrollPosition === 2 && <BubbleContainer props={worldProps} />
                }
            </ScrollLocation>
            {
                scrollPosition < 2 &&
                <ArrowDownWrapper>
                    <ArrowDown onClick={scrollDown} />
                </ArrowDownWrapper>
            }
            {
                scrollPosition > 0 &&
                <ArrowUpWrapper>
                    <ArrowUp onClick={scrollUp} />
                </ArrowUpWrapper>
            }
        </LandingWrapper>
    );
}

export default Landing;

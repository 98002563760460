import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { A, LangButton } from '#ui';
import { useStore } from '#hooks';
import { animated, useSpring } from 'react-spring';
import Modal from '#pages/components/modal';
import About from '../about/About';


const HeaderWrapper = styled(animated.header)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    background-color: transparent;
`

const HeaderLogo = styled.div`
    margin-left: 4vw;
`

const NavigationMenu = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3vw;
    font-size: 1.1rem;
    margin-right: 6vw;
`

const Header = () => {
    const [lang] = useStore(state => state.lang, 'fr');
    const [open, setOpen] = useState(false);
    const [hideHeader] = useStore(state => state.hideHeader, false);
    const [props, set] = useSpring(() => ({
        opacity: 1,
        config: {
            duration: 800
        }
    }))

    const handleClick = () => {
        setOpen(true);
    }

    useEffect(() => {
        if (hideHeader) {
            set({
                opacity: 0
            })
        }
        else {
            set({
                opacity: 1
            })
        }
    }, [hideHeader])
    return (
        <HeaderWrapper style={props}>
            <HeaderLogo>
                123
            </HeaderLogo>
            <NavigationMenu>
                <A onClick={handleClick}>about</A>
                {/* <LangButton /> */}
            </NavigationMenu>
            {
                open &&
                <Modal open={open} onClose={() => setOpen(false)}>
                    <About/>
                </Modal>
            }
        </HeaderWrapper>
    );
}

export default Header;


// ci = [x, y, r] cj = [x, y, r]
const detectCollision = (p1, p2, cancel = false) => {

    // avoid clipping
    if (p1.lastCollision === p2 && p2.lastCollision === p1) {
        return false;
    }

    // collision distance
    const collisionDistance = (p1.radius / 2) + (p2.radius / 2)

    let x1 = p1.x + p1.radius /2
    let y1 = p1.y + p1.radius /2
    let x2 = p2.x + p2.radius /2
    let y2 = p2.y + p2.radius /2
    // squared distance
    const distance = Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2);

    let collided = distance < collisionDistance * collisionDistance

    if (collided) {
        p1.setLastCollision(p2);
        p2.setLastCollision(p1);
    }

    return collided;
}

export default detectCollision;
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Header } from '#components/header';
import "./index.css";
import Landing from './pages/landing';


function App() {
    return (
        <div className="App">
            <Router>
                <Header />
                <Switch>
                    <Route path="/">
                        <Landing />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}
export default App;
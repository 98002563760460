import { Vector, Vector2 } from '../helper';
import { Particle } from '../';




const getUserParticleCollisionResolution = (ball, user) => {

    const collisionNormal = Vector.create(ball.x, ball.y, user.x, user.y);
    const normalizedCollisionNormal = Vector.normalizeV2(collisionNormal);

    // get collision vector from ball vector
    const ballCollisionVectorComponent = Vector.scalarProduct(normalizedCollisionNormal, Vector.dot(normalizedCollisionNormal, ball.vector));

    // get collision vector from user input
    const collisionComponent = Vector.dot(normalizedCollisionNormal, user.vector)
    console.log(user.vector);
    console.log(collisionComponent);

    const userCollisionVectorComponent = Vector.scalarProduct(normalizedCollisionNormal, collisionComponent);

    // vector from impact
    const ballImpactVector = Vector.add(ball.vector, Vector.scalarProduct(ballCollisionVectorComponent, -2));
    return Vector.add(ballImpactVector, userCollisionVectorComponent);
}

export {
    getUserParticleCollisionResolution,
}
import { Vector, Vector2 } from '../helper';
import { Particle } from '../';


// v1 = (m1 - m2)/(m1 + m2)*u1 + (2*m2)/(m1 + m2)*u2
// v2 = (2*m1)/(m1 + m2)*u1 + (m2 - m1)/(m1 + m2)*u2
const getParticleCollisionResolution = (p1, p2) => {
    
    // 1 get collision normal vector
    const collisionNormal = Vector.create(p1.x, p1.y, p2.x, p2.y);
    const normalizedCollisionNormal = Vector.normalizeV2(collisionNormal);
    
    // 2 get each collision vector component
    const p1CollisionVectorComponent = Vector.dot(normalizedCollisionNormal, p1.vector)
    const p2CollisionVectorComponent = Vector.dot(normalizedCollisionNormal, p2.vector);
    
    // 3 get each remainder vector component
    const p1RemainderVectorComponent = Vector.subtract(p1.vector, Vector.scalarProduct(normalizedCollisionNormal, p1CollisionVectorComponent))
    const p2RemainderVectorComponent = Vector.subtract(p2.vector, Vector.scalarProduct(normalizedCollisionNormal, p2CollisionVectorComponent))
    
    // 4 compute new vector force same mass    
    const v1 = (p1.mass - p2.mass) / (p1.mass + p2.mass) * p1CollisionVectorComponent + (2 * p2.mass) / (p1.mass + p2.mass) * p2CollisionVectorComponent

    const v2 = 2 * p1.mass / (p1.mass + p2.mass) * p1CollisionVectorComponent + (p2.mass - p1.mass) / (p1.mass + p2.mass) * p2CollisionVectorComponent

    // 5 rebuild the vector
    const p1Vector = Vector.add(Vector.scalarProduct(normalizedCollisionNormal, v1), p1RemainderVectorComponent);
    const p2Vector = Vector.add(Vector.scalarProduct(normalizedCollisionNormal, v2), p2RemainderVectorComponent);

    // 6 return the new vectors
    return { vector1: p1Vector, vector2: p2Vector };
}


const checkingVectorDotProduct = () => {
    // setup
    const v1 = new Vector2(10, -10);
    const v2 = new Vector2(-10, 10);
    const p1 = new Particle([1, 1], 2, v1);
    const p2 = new Particle([3, 1], 2, v2);

    //return getCollisionResolution(p1, p2);
}

const checkV1CollisionResolution = () => {
    // setup
    const v1 = new Vector2(10, -10);
    const v2 = new Vector2(-10, 10);
    const p1 = new Particle([1, 1], 2, v1);
    const p2 = new Particle([3, 1], 2, v2);

    // return collisionNormal.magnitude
    return getParticleCollisionResolution(p1, p2);
}



export {
    getParticleCollisionResolution,
    checkV1CollisionResolution
};

import React, { useState, useEffect, forwardRef, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import Modal from '#pages/components/modal';
import { Board } from './';
import { Control } from './';
import { checkWinner, getAiMove, findMoves } from "./game";
import WinnerBanner from './board/WinnerBanner';
import { animated } from 'react-spring';

const Connect4Wrapper = styled(animated.main)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const Connect4 = forwardRef(({ props }, ref) => {
    const newBoard = new Array(42).fill(null);
    const boardRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [board, setBoard] = useState(newBoard);
    const [aiturn, setAiturn] = useState(false);
    const [winner, setWinner] = useState(false);
    const [cellsize, setCellSize] = useState(6);


    const updateBoard = (index) => {
        const moves = findMoves(board);
        board[moves[index]] = 1;
        setBoard([...board]);
    }

    const changeTurn = () => {
        setAiturn(!aiturn);
    }

    const reset = () => {
        setBoard([...newBoard]);
        setOpen(false);
        setAiturn(false);
        setWinner(false);
    }

    useEffect(() => {
        if (aiturn && winner === false) {
            let move = getAiMove(board);
            board[move] = 2;
            setBoard([...board]);
            let winner = checkWinner(board);
            setWinner(winner);
            setAiturn(false);
        }

        if (winner !== false) {
            console.log(winner);
            setAiturn(true);
            setOpen(true);
        }

        const handleResize = () => {
            let preferredCellSize = 2;
            
            if (ref.current && ref.current.style.opacity > 0) {
                setCellSize(preferredCellSize);
            }
        }

        window.addEventListener('resize', handleResize);
        return (() => {
            window.removeEventListener('resize', handleResize);
        })
    }, [aiturn, winner, cellsize])

    useLayoutEffect(() => {
        const height = window.innerHeight / 170;
        const width = window.innerWidth / 150;
        const preferredCellSize = Math.min(width, height);
        setCellSize(preferredCellSize);
    })

    return (
        <Connect4Wrapper ref={ref} style={props}>
            <Control updateBoard={updateBoard} changeTurn={changeTurn} aiturn={aiturn} cellsize={cellsize} reset={reset} />
            <Board board={board} winner={winner} aiturn={aiturn} cellsize={cellsize} ref={boardRef} />
            {open && <Modal open={open} onClose={() => setOpen(false)}>
                <WinnerBanner winner={winner} reset={reset} />
            </Modal>}
        </Connect4Wrapper>
    );
})

export default Connect4;

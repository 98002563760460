export const findMoves = (game) => {
    let moves = new Array(7).fill(null);
    const starts = moves.map((_, i) => 35 + i);
    moves = starts.map(start => {
        for(let i = start; i >= 0; i -= 7){
            if (game[i] === null) {
                return i;
            }
        }
        return null
    })
    return moves;
}
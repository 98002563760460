import { getFutureWinningMoves, getImmediateWinningMoves, checkWinner, findMoves } from './';

const getBasicMove = (game, moveCount) => {
    moveCount = moveCount || game.filter(p => p !== null).length;
    let player = moveCount % 2 + 1;
    const otherPlayer = player === 1 ? 2 : 1;
    // 1. find immediate winning move;
    const immediateWinningMoves = getImmediateWinningMoves(game, player);
    if (immediateWinningMoves.length > 0) {
        return getRandomMove(immediateWinningMoves);
    }
    // 2. find block opponent immediate winning moves;
    const immediateBlockingMoves = getImmediateWinningMoves(game, otherPlayer);
    if (immediateBlockingMoves.length > 0) {
        return getRandomMove(immediateBlockingMoves);
    }
    // 3. find future winning move
    const futureWinningMove = getFutureWinningMoves(game, player)
    if (futureWinningMove.length > 0) {
        return getRandomMove(futureWinningMove);
    }
    // 4. find future winning move block
    const futureWinningMoveBlock = getFutureWinningMoves(game, otherPlayer);
    if (futureWinningMoveBlock.length > 0) {
        return getRandomMove(futureWinningMoveBlock);
    }
    // 5. book start, go for the middle
    const moves = findMoves(game, player);
    if (moveCount < 8 && moves[3] !== null) {
        return moves[3];
    }
    return getRandomMove(moves.filter(move => move !== null));
}


const getRandomMove = (array) => {
    let num = array.length
    if (num === 0) return -1;
    return array[Math.floor(Math.random() * num)]
}

const playLotsOfRandomGames = (game, iteration, moveCount) => {
    iteration = iteration || 10;
    let outcome = {};
    for (let i = 0; i < iteration; i++) {
        let tempOutcome = playRandomGame(game, moveCount);
        outcome[tempOutcome[0]] === undefined ? outcome[tempOutcome[0]] = tempOutcome[1] : outcome[tempOutcome[0]] += tempOutcome[1];
    }
    return outcome;
}

const getAiMove = (game, iteration) => {
    iteration = iteration || 100;
    let moveCount = game.filter(p => p !== null).length;
    if (moveCount < 8) {
        return getBasicMove(game, moveCount);
    }
    let outcomes = playLotsOfRandomGames(game, iteration, moveCount);
    let currentMove = -1;
    let currentResult = -1000;
    for (const move in outcomes) {
        if (outcomes[move] > currentResult) {
            currentResult = outcomes[move];
            currentMove = move;
        }
    }
    if (currentMove === -1) {
        return getBasicMove(game, moveCount);
    }

    return Number(currentMove);
}

const playMove = (game, move, player) => {
    player = player || game.filter(p => p !== null).length % 2 + 1;
    let newGame = [...game];
    newGame[move] = player;
    return newGame;
}

const playRandomGame = (game, moveCount) => {

    let initialPlayer = moveCount % 2 + 1;
    let initialMove = getBasicMove(game, moveCount);
    let gameState = playMove(game, initialMove, initialPlayer);
    let winner = checkWinner(gameState, moveCount + 1);
    if (winner !== false) {
        return [initialMove, 1];
    }
    do {
        moveCount++;
        let move = getBasicMove(gameState, moveCount);
        gameState[move] = moveCount % 2 + 1;
        winner = checkWinner(gameState, moveCount);
    } while (winner === false && moveCount < 42)
    if (winner === false) {
        return [initialMove, 0]
    }

    return [initialMove, winner.winner === initialPlayer ? 1 : -1];
}


export {
    getBasicMove,
    getAiMove,
    playRandomGame
}
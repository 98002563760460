import React from 'react';
import styled from 'styled-components'

const ButtonWrapper = styled.button`
    background-color:transparent;
    outline:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    transition: all .3s ease;
    :hover&{
        transform: scale(1.2);
        transition: all .3s ease;
    }
`

const ArrowDown = ({ onClick, size, color }) => {
    color = color || '#fff';
    size = size || '24';

    return (
        <ButtonWrapper onClick={onClick}>
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.51501 8.465L12 16.95L20.485 8.465L19.071 7.05L12 14.122L4.92901 7.05L3.51501 8.465Z" fill={color} />
            </svg>
        </ButtonWrapper>
    );
}

export default ArrowDown;

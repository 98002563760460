import React, { useRef, useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring';
import Portal from './Portal';
import './Modal.css';


// TODO add backdrop types

export default function Modal({ open, onClose, children }) {
    const backdrop = useRef(null);
    const container = useRef(null);
    const MODAL_CLOSE = "modal-close";

    // --- CLOSING MODAL ---
    const onRestCallback = (e) => {
        if (props.opacity.value === 0) {
            onClose();
        }
    }
    const handleClose = (event) => {
        const name = event.target.getAttribute('name');
        console.log(name);

        if(name === MODAL_CLOSE || event.target === backdrop.current){
            set({
                xy: [0, 100],
                opacity: 0
            })
        }
    }
    // --- CLOSING MODAL ---

    // --- ANIMATION ---
    const [props, set] = useSpring(() => ({
        opacity: 0,
        xy: [0, 100],
        onRest: onRestCallback
    }));
    const transform = (x, y) => `translate(0px, ${y}px)`;
    // --- ANIMATION ---
    if (open) {
        set({
            opacity: 1,
            xy: [0, 0]
        })
    }
    
    useEffect(() => {
        if (backdrop.current) backdrop.current.addEventListener('click', handleClose);
        return () => {
            if (backdrop.current) {
                backdrop.current.removeEventListener('click', handleClose);
            };
        }
    }, [backdrop])    
    return (
        <>
            { open &&
                <Portal>
                    <animated.div ref={backdrop} style={{ opacity: props.opacity }} className="default-modal-backdrop">
                        <animated.div ref={container} className="default-modal-content" style={{ ...props, transform: props.xy.interpolate(transform) }}>
                            <div className="default-modal-close" name={MODAL_CLOSE} onClick={handleClose}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="window-close" role="img" viewBox="0 0 512 512">
                                    <path fill="currentColor" d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z">
                                    </path>
                                </svg>
                            </div>
                            {children}
                        </animated.div>
                    </animated.div>
                </Portal>
            }
        </>
    )
}

import { Vector2 } from './helper';

export default class Particle {
    constructor(
        position = [0, 0],
        radius = 30,
        vector,
        mass = 1,
    ) {
        this.radius = radius;
        this.mass = mass;
        this.x = position[0];
        this.y = position[1];
        this.vector = vector || new Vector2(this.generateVector(), this.generateVector());
        this.lastCollision = null;
    }

    generateVector = () => {
        let direction = Math.random() > .5 ? -1 : 1
        return Math.random() * 2 * direction;
    }

    updatePosition = (x, y) => {
        this.x = x;
        this.y = y;
    }

    updateVector = (vx, vy) => {
        this.vx = vx;
        this.vy = vy;
    }

    setLastCollision = (p) => {
        this.lastCollision = p;
    }
}
import React, { useLayoutEffect, useRef } from 'react';
import { useGameLoop } from '#hooks';



export const Bubble = ({ particle }) => {
    const bubbleRef = useRef(null);
    useGameLoop(() => {
        if (bubbleRef.current) {
            bubbleRef.current.style.transform = `translate3D(${particle.x}px, ${particle.y}px, 0)`;
        }

    }, 16)
    let opacity = 1 - (particle.radius / 100);

    return (
        <div ref={bubbleRef} style={{ position: 'absolute', color: 'white', width: `${particle.radius}px`, height: `${particle.radius}px`, borderRadius: '50%', backgroundColor: `rgba(255,255,255,${opacity})` }}>
        </div>
    );
}

import React from 'react';
import styled from 'styled-components';

const WinnerBannerWrapper = styled.div`
    width: 15rem;
    height: 8rem;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    font-size:2rem;
    font-weight: bold;
`

const WinnerButton = styled.div`
    border: 1px solid black;
    border-radius: 3px;
    padding: .3rem;
    margin: 1.2rem;
    font-size: 1.2rem;
    font-weight: normal;
    outline:none;
    cursor: pointer;
    &:hover{
        transform: scale(1.05);
        background-color: #000;
        color: #fff;
        transition: all ease .3s;
    }
`


const WinnerBanner = ({ winner, reset }) => {
    let message = winner.winner === 1 ? "Human wins" : "Ai wins";

    return (
        <WinnerBannerWrapper>
            <span>{message}</span>
            <div>
                <WinnerButton onClick={reset}>Play again ?</WinnerButton>
            </div>
        </WinnerBannerWrapper>
    );
}
export default WinnerBanner;

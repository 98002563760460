import React, { useEffect } from 'react';
import { useLanguage } from '#hooks';
import styled from 'styled-components';

const LangButtonWrapper = styled.div`
    border: none;
    color: #fff;
    outline:none;
    width:2.6rem;
    height:2.6rem;
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255, 0.1);
    border-radius: 50%;
`

const LangButton = () => {
    const { oppositeLanguage, changeLanguage, currentLanguage } = useLanguage();
    useEffect(() => {
    }, [currentLanguage])

    return (
        <LangButtonWrapper onClick={changeLanguage}>
            {oppositeLanguage()}
        </LangButtonWrapper>
    );
}

export default LangButton;

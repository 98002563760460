import React, { useLayoutEffect, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import BoardCell from './BoardCell';

const BoardWrapper = styled.div`
    display: inline-grid;
    grid-template-columns: repeat(7, 1fr);
    width: calc(7 * ${props => props.width}rem);
    position : relative;
    box-sizing: content-box;
    border-right: calc(0.1 * ${props => props.width}rem) solid black;
    border-bottom: calc(0.16 * ${props => props.width}rem) solid black;
    transform-style: preserve-3d;
`

const BoardFoot = styled.div`
    position : absolute;
    box-sizing: content-box;
    ${'' /* border-right: 15px solid black; */}
    bottom: calc(-0.6 * ${props => props.width}rem);;
    width: calc(calc(7 * ${props => props.width}rem) + calc(.9 * ${props => props.width}rem));
    transform: translateX(calc(-.42 * ${props => props.width}rem)););
    border-radius: 2.5rem 2.5rem 0 0;
    height: calc(0.55 * ${props => props.width}rem);
    background-color: black;
`

// 
/**
 *   0  1  2  3  4  5  6
 *   7  8  9  10 11 12 13
 *   14 15 16 17 18 19 20
 *   21 22 23 24 25 26 27
 *   28 29 30 31 32 33 34
 *   35 36 37 38 39 40 41
 * @param {board} param0 
 */
const Board = forwardRef(({ board, cellsize }, ref) => {
    return (
        <BoardWrapper width={cellsize} ref={ref}>
            {board.map((cell, index) => {
                return <BoardCell value={cell} cellsize={cellsize} boardRef={ref} key={index} />;
            })}
            <BoardFoot width={cellsize}>

            </BoardFoot>
        </BoardWrapper>
    );
})

export default Board;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from "#themes";

const ControlWrapper = styled.div`
    position: relative;
    display: inline-grid;
    grid-template-columns: repeat(7, ${props => props.cellsize}rem);
    justify-items: center;
    height:${props => props.cellsize}rem;
`

const ControlButton = styled.div`
    background-color: #333;
    height: calc(${props => props.cellsize}rem * .75);
    width: calc(${props => props.cellsize}rem * .75);
    border-radius: 50%;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    

    ${props => {
        if (props.clickable) {
            return `
            &:hover{
        transform: scale(1.2);
        background-color: ${colors.eyelashViper};
        transition: all .3s ease;
        filter: opacity(70%);
        cursor:pointer;`
        }
    }
    }}
    
`

const ResetButton = styled.button`
    outline: none;
    border: 1px solid ${colors.eyelashViper};
    border-radius: 3px;
    cursor: pointer;
    color: ${colors.eyelashViper};
    padding: .4rem .7rem;
    position: absolute;
    right:1rem;
    top: -3.5rem;
    background-color:transparent;
    transition: all ease .3s;
    @media(hover:hover){
        &:hover{
            color: #fff;
            border: 1px solid #fff;
            transition: all ease .3s;
        }
    }
`


const Control = ({ cellsize, updateBoard, changeTurn, aiturn, reset }) => {
    const [clickable, setClickable] = useState(false);
    const buttonArray = new Array(7).fill(0).map((_, i) => i);

    const handleClick = (event) => {
        let index = Number(event.target.getAttribute('name'));
        if (!clickable) {
            return;
        }
        updateBoard(index);
        changeTurn();
        setClickable(false);
    }

    useEffect(() => {
        if (!aiturn) {
            setClickable(true);
        }
        else{
            setClickable(false);
        }
    }, [aiturn])


    return (
        <ControlWrapper cellsize={cellsize}>
            <ResetButton onClick={reset}>RESET</ResetButton>
            {buttonArray.map((value, index) => {
                return <ControlButton key={index} clickable={clickable} name={value} cellsize={cellsize} onClick={handleClick} />
            })}
        </ControlWrapper>
    );
}

export default Control;
